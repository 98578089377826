<template>
    <div class="cf-container">
        <div class="cf-action-container">
            <md-button
                title="Add custom action"
                class="md-primary md-just-icon md-round"
                @click.stop="handleAddCarrierQuotesAction()"
            >
                <md-icon>add</md-icon>
            </md-button>
        </div>
        <form-group label="Select custom action type" class="type-filter">
            <md-select v-model="selectedAction" @md-selected="actionSelected">
                <md-option v-for="(action, index) in otherCustomActionsOptions" :key="index" :value="action.value">
                    {{ action.text }}
                </md-option>
            </md-select>
        </form-group>
        <keep-alive>
            <component
                :is="actionListComponent"
                :custom-actions="filteredCustomActions"
                @updateClicked="updateClickedHandler"
                @deleteClicked="deleteClickedHandler"
            ></component>
        </keep-alive>
        <div class="text-center" v-if="filteredCustomActions.length == 0">
            <p class="no-result-message">No results matching your search/filter could be found.</p>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { GeneralMixin } from '@/mixins/GeneralMixin';
import { mapGetters, mapActions } from 'vuex';
import { showErrorMessage } from '@/helpers';
import { TEAM_CUSTOM_ACTION_DEFAULTS } from '@/utils/defaults';
import { TEAM_CUSTOM_ACTION_TYPE_OPTIONS } from '@/utils/CustomActionConstants';
import CreateCustomAction from './CreateCustomAction';

const PlaceCarrierBookingList = () => import('./PlaceCarrierBooking/PlaceCarrierBookingList');
const GetQuotesFromCarriersList = () => import('./GetQuotesFromCarriers/GetQuotesFromCarriersList');
const OfferToTeamMembersList = () => import('./OfferToTeamMembers/OfferToTeamMembersList');

export default {
    name: 'OtherCustomActions',
    mixins: [GeneralMixin],
    props: {
        eventTrigger: { type: String, default: null }
    },
    data() {
        return {
            selectedAction: 'GetQuotesFromCarriers',
            selectedActionIndex: null,
            actionTypeOptions: TEAM_CUSTOM_ACTION_TYPE_OPTIONS,
            teamEvents: []
        };
    },
    computed: {
        ...mapGetters({
            customActions: 'custom-actions/customActions',
            oldJobOffersEnabled: 'user/oldJobOffersEnabled'
        }),
        otherCustomActionsOptions() {
            const otherActions = this.actionTypeOptions.filter((x) => x.group === 'Others');
            if (this.oldJobOffersEnabled) {
                const jobOfferIndex = otherActions.findIndex((x) => x.value === 'OfferToTeamMembers');
                if (jobOfferIndex > -1) 
                    otherActions.splice(jobOfferIndex, 1);
            }
            return otherActions;
        },
        filteredCustomActions() {
            if (!this.customActions || !this.customActions.length) 
                return [];
            const filtered = this.customActions
                .filter((x) => x.action === this.selectedAction)
                .sort((a, b) => (a.eventTriggerName > b.eventTriggerName ? 1 : -1));
            return [...filtered];
        },
        actionListComponent() {
            if (this.selectedAction === 'GetQuotesFromCarriers') 
                return GetQuotesFromCarriersList;
            if (this.selectedAction === 'PlaceCarrierBooking') 
                return PlaceCarrierBookingList;
            if (this.selectedAction === 'OfferToTeamMembers') 
                return OfferToTeamMembersList;

            return null;
        }
    },
    methods: {
        ...mapActions('custom-actions', ['FETCH_EVENT_TRIGGERS', 'DELETE_EVENT_TRIGGER']),
        handleAddCarrierQuotesAction() {
            const customAction = { ...TEAM_CUSTOM_ACTION_DEFAULTS() };
            customAction.action = this.selectedAction;
            this.$modal
                .show(CreateCustomAction, {
                    customActionModel: customAction,
                    isDisabled: true,
                    update: false
                })
                .then(({ result, model }) => {
                    if (result === 'ok') {
                        this.$modal.hide();
                    }
                });
        },
        async deleteEventTrigger(teamCustomActionId) {
            this.$_handleLoaderState(true, 'DELETING EVENT TRIGGER...');
            await this.DELETE_EVENT_TRIGGER({ actionType: this.selectedAction, teamCustomActionId })
                .then(() => {
                    this.$notify({
                        message: 'Event trigger deleted.',
                        type: 'success'
                    });
                })
                .catch((e) => {
                    const message = 'Could not remove event trigger.';
                    showErrorMessage(this, message, e);
                })
                .finally(() => {
                    this.$_handleLoaderState(false);
                });
        },
        async updateClickedHandler(args) {
            const customAction = this.customActions.find((x) => x.teamCustomActionId === args.teamCustomActionId);
            if (!customAction) 
                return;

            const customActionModel = cloneDeep(customAction);
            if (customActionModel.settings?.conditions && Array.isArray(customActionModel.settings?.conditions)) {
                // PlaceCarrierBooking and "Custom" preferredQuote
                for (let i = 0; i < customActionModel.settings.conditions.length; i++) {
                    customActionModel.settings.conditions[i].tempKey = _.random(-1, -10000);

                    if (Array.isArray(customActionModel.settings.conditions[i].subConditions)) {
                        for (let j = 0; j < customActionModel.settings.conditions[i].subConditions.length; j++) {
                            customActionModel.settings.conditions[i].subConditions[j].tempKey = _.random(-1, -10000);
                        }
                    }
                }
            }

            this.$modal
                .show(CreateCustomAction, {
                    customActionModel,
                    isUpdate: true
                })
                .then(({ result, model }) => {
                    if (result === 'ok') {
                        this.$modal.hide();
                    }
                });
        },
        async deleteClickedHandler(args) {
            this.$messageBox
                .show({
                    class: 'sm-modal-container',
                    title: 'Delete Event Trigger',
                    body: 'Are you sure you want to delete this event trigger?',
                    buttons: ['Confirm', 'Cancel']
                })
                .then(async (response) => {
                    if (response.toLowerCase() === 'confirm') {
                        this.isListLoading = true;
                        await this.deleteEventTrigger(args.teamCustomActionId);
                    }
                });
        },
        async actionSelected(args) {
            this.FETCH_EVENT_TRIGGERS({ actionType: this.selectedAction });
        }
    },
    mounted() {
        this.FETCH_EVENT_TRIGGERS({ actionType: this.selectedAction });
    }
};
</script>

<style lang="scss" scoped>
.actions {
    width: 6%;
}

.type-filter {
    margin: 0;
    width: 150px;
    position: absolute;
    right: 65px;
    top: -36px;
}

::v-deep .md-disabled {
    color: #aaa;
}

::v-deep .md-disabled:hover {
    cursor: no-drop;
}
</style>
